.App {
  position: relative;
  font-family: "Exo";
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  color: #8b0000 !important;
}

::-webkit-scrollbar-thumb {
  background-color: #000000;
}

::-webkit-scrollbar-thumb:hover {
  background-color: black;
}

.navBorderRadius {
  border-radius: 0px 0px 30px 30px;
}

.black-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, black, black);
  /* mix-blend-mode: multiply; */
  opacity: 0.5;
  z-index: 1;
  transition: opacity 0.3s linear;
  /* Adjust the opacity as needed */
}

.animate-underline {
  position: relative;
}

.animate-underline::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px; /* Adjust the underline thickness as needed */
  bottom: 0;
  left: 0;
  background-color: #581c1c; /* Underline color when active */
  transform: scaleX(0); /* Initial scale to hide the underline */
  transform-origin: 0 50%;
  transition: transform 0.3s ease; /* Animation duration and easing */
}

.swiper-vertical > .swiper-pagination-bullets,
.swiper-pagination-vertical.swiper-pagination-bullets {
  right: 3rem !important;
}

@media only screen and (max-width: 768px) {
  .swiper-vertical > .swiper-pagination-bullets,
  .swiper-pagination-vertical.swiper-pagination-bullets {
    right: 1.5rem !important;
  }
}

.swiper-pagination-vertical.swiper-pagination-bullets
  .swiper-pagination-bullet {
  border-radius: 0% !important;
  background-color: white !important;
  height: 1px !important;
  width: 10px !important;
  margin: 30px 0px !important;
  opacity: 1 !important;
  transition: height 0.3s ease-in-out;
}

.swiper-pagination-vertical.swiper-pagination-bullets
  .swiper-pagination-bullet-active {
  height: 7px !important;
  transition: height 0.3s ease-in-out;
}

a {
  text-decoration: none !important;
}

.textAnimationDown {
  animation: revealFromDown 1s ease-in-out;
}

@keyframes revealFromDown {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.textAnimationUp {
  animation: revealFromUp 1s ease-in-out;
}

@keyframes revealFromUp {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.logoBannerBg {
  background: radial-gradient(
    circle,
    hsla(0, 0%, 0%, 0.9),
    hsla(0, 0%, 50%, 0.5)
  );
}

.swiper-pagination-bullet {
  background-color: #d9d9d9 !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background: #ff6b00 !important;
}

.glassBackground {
  /* From https://css.glass */
  background: rgba(22, 22, 22, 0.62);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.2px);
  -webkit-backdrop-filter: blur(9.2px);
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9); /* Adjust the alpha (last) value for opacity */
  z-index: 100; /* Adjust the z-index as needed */
}